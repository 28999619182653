import React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import LoginLogout from "./LoginLogout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
const SEDALIA_CONTACT_US = 'https://www.sedalia.com/contact/';
const SEDALIA_FACEBOOK = 'https://www.facebook.com/people/City-of-Sedalia-Missouri/100064592056037/';
const SEDALIA_TWITTER = 'https://twitter.com/CityofSedalia';
const SEDALIA_YOUTUBE = 'https://www.youtube.com/@sedaliamo1923';
function Footer({ className }) {
    return (<footer className={cx("footer", className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <a className="footer-link" target="_blank" href={SEDALIA_CONTACT_US}>
            <Trans>Contact Us</Trans>
          </a>
          <a className="footer-link" target="_blank" role="img" href={SEDALIA_FACEBOOK} aria-label="facebook-link">
            <FontAwesomeIcon icon={faFacebookF}/>
          </a>
          <a className="footer-link" target="_blank" role="img" href={SEDALIA_TWITTER} aria-label="twitter-link">
            <FontAwesomeIcon icon={faTwitter}/>
          </a>
          <a className="footer-link" target="_blank" role="img" href={SEDALIA_YOUTUBE} aria-label="youtube-link">
            <FontAwesomeIcon icon={faYoutube}/>
          </a>
          <LoginLogout className="footer-link"/>
        </nav>
      </div>
    </footer>);
}
export default Footer;
